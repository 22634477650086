<template>
  <div class="page-404 flex-col justify-align-center">
    <Nav />
    <img class="img-404" src="../../assets/img/404.png" alt="可搭吖">
    <span class="txt-404">哎呀，打不开网页了呢</span>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  components: {
    Nav
  },
  data() {
    return {}
  },
  mounted() { },
  computed: {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.page-404 {
  min-height: 100vh;
  margin: 0 auto;
  background:
    radial-gradient(95.18% 95.18% at 4.82% 2.16%, #FAF8EE 0%, rgba(255, 255, 255, 0.05) 100%),
    radial-gradient(98.41% 95.65% at 98.41% 0.93%, #E9FFF0 0%, rgba(255, 255, 255, 0.05) 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 75.6%, #FFFFFF 100%);

  .img-404 {
    width: 363rem;
    height: 342rem;
  }

  .txt-404 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16rem;
    color: #999999;
    line-height: 19rem;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 32rem;
  }
}
</style>